import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import { useDataLayerActions } from '../../hooks';
import useSearch from './CollectionSearchspring/useSearch';

import { Spinner } from '../../snippets';
import { CollectionSearchspring } from './CollectionSearchspring';

export function CollectionSearch() {
  const isFirstLoad = useRef(true);
  const router = useRouter();
  const { query } = router;
  const queryTerm = query.term;

  const [savedProductId, setSavedProductId] = useState(null);

  const {
    setParameters,
    totalProducts,
    pageResults,
    selectedResultsPerPage,
    currentResultsPage,
    totalPages,
    loadedPages,
    selectedSort,
    sortOptions,
    filters,
    filterSummary,
    loadSort,
    selectSort,
    loadFilters,
    addToFilters,
    removeFromFilters,
    clearFilters,
    goToPreviousPage,
    goToFollowingPage,
    goToSpecificPage,
    updatePageInView,
  } = useSearch();

  useEffect(() => {
    if (router.isReady && isFirstLoad.current && queryTerm) {
      const filtersFromParams = Object.entries(router.query).flatMap(([key, value]) => {
        if (!key.startsWith('filter.')) return [];
        return Array.isArray(value) ? value.map(v => ({ key, value: v })) : [{ key, value }];
      });

      const sortFromParams = Object.entries(router.query).flatMap(([key, value]) => {
        if (!key.startsWith('sort.')) return [];
        return Array.isArray(value) ? value.map(v => ({ field: key.replace(/^sort\./, ''), direction: v })) : [{ field: key.replace(/^sort\./, ''), direction: value }];
      });

      const handleLoad = () => {
        if (filtersFromParams.length > 0) {
          loadFilters(filtersFromParams);
        }
        if (sortFromParams.length > 0) {
          loadSort(sortFromParams);
        }
        if (router.query.page) {
          goToSpecificPage(router.query.page);
        }
      };

      setTimeout(() => {
        handleLoad();
        setParameters({ query: queryTerm });
        isFirstLoad.current = false;
      }, 100);
    }
  }, [router.isReady, setParameters]);

  useEffect(() => {
    const savedProductId = localStorage.getItem('savedProductId');
    if (savedProductId) {
      setSavedProductId(savedProductId);
    }
  }, []);

  // Data layer actions
  const { sendViewSearchResultsEvent } = useDataLayerActions();
  useEffect(() => {
    sendViewSearchResultsEvent({
      products: pageResults,
      isSearchPage: true,
    });
  }, [pageResults, sendViewSearchResultsEvent]);

  const loadingGrid = new Array(48).fill(0);

  return (
    <div className="px-contained py-contained">
      {pageResults?.length > 0 && (
        <>
          {totalProducts > 0 ? (
            <>
              <div className="py-contained !pt-0">
                <p className="text-body">
                  Found <span className="font-medium">{totalProducts}</span>{' '}
                  {totalProducts === 1 ? 'result' : 'results'} for:
                </p>
                <h1 className="text-title-h2">{queryTerm}</h1>
              </div>
              <CollectionSearchspring
                handle="search"
                products={pageResults}
                totalProducts={totalProducts}
                selectedResultsPerPage={selectedResultsPerPage}
                currentResultsPage={currentResultsPage}
                totalPages={totalPages}
                loadedPages={loadedPages}
                selectedSort={selectedSort}
                sortOptions={sortOptions}
                filters={filters}
                filterSummary={filterSummary}
                selectSort={selectSort}
                loadFilters={loadFilters}
                addToFilters={addToFilters}
                removeFromFilters={removeFromFilters}
                clearFilters={clearFilters}
                goToPreviousPage={goToPreviousPage}
                goToFollowingPage={goToFollowingPage}
                goToSpecificPage={goToSpecificPage}
                updatePageInView={updatePageInView}
                savedProductId={savedProductId}
              />
            </>
          ) : null}
        </>
      )}

      {totalProducts === 0 && (
        <>
          <div className="py-contained !pt-0">
            <p className="text-body">Found 0 results for:</p>
            <h1 className="text-title-h2">{queryTerm || ''}</h1>
          </div>
          <div className="mx-auto flex max-w-[var(--content-max-xl-width)] flex-col">
            <div className="filter-panel flex h-[48px] w-full flex-row flex-wrap bg-background"></div>
            <div className="collection-container mt-5 flex w-full flex-row flex-wrap">
              <div className="order-4 w-full">
                <div className="mx-auto grid grid-cols-2 gap-x-5 gap-y-6 md:grid-cols-2 md:px-0 lg:grid-cols-4">
                  {loadingGrid.map((_, index) => (
                    <li className="flex flex-col" key={index}>
                      <div className="aspect-[159/232] w-full bg-offWhite"></div>
                    </li>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {!Array.isArray(pageResults) && totalProducts > 0 && (
        <div className="flex min-h-[20rem] items-center justify-center">
          <Spinner width="32" />
        </div>
      )}
    </div>
  );
}

CollectionSearch.displayName = 'CollectionSearch';
