import { Fragment, useMemo, useEffect, useState } from 'react';
import { useSettings } from '@backpackjs/storefront';

import { CollectionSearchspringPromoTile } from './CollectionSearchspringPromoTile';
import { ProductItem } from '../../ProductItem';

export function CollectionSearchspringGrid({
  collectionTitle,
  collectionHandle,
  collectionProductsData,
  products,
  enabledFilters,
  isSearchResults,
  promoTiles,
  swatchesMap,
  totalProducts,
  currentPage,
}) {
  const settings = useSettings();
  const { pagination, productItem } = { ...settings?.collection };

  const {
    state: { minLoadedPage, maxLoadedPage, totalPages },
    actions: { loadMoreProducts, loadPreviousProducts },
    refs: { loadMoreRef },
  } = collectionProductsData;

  const hasPreviousProducts = minLoadedPage > 1;
  const hasMoreProducts = maxLoadedPage < totalPages;
  const isCollection = isSearchResults ? false : true;

  const progressMessage = useMemo(() => {
    if (!pagination?.progressMessage || !hasMoreProducts) return null;
    return pagination.progressMessage
      .replace('{{total}}', totalProducts)
      .replace('{{loaded}}', maxLoadedPage * 48);
  }, [maxLoadedPage, totalPages, pagination?.progressMessage]);

  return (
    <>
      {hasPreviousProducts && pagination && (
        <div
          className="mb-10 flex flex-col items-center gap-3"
        >
          <button
            aria-label={pagination.loadText}
            className={`${pagination.buttonStyle}`}
            onClick={loadPreviousProducts}
            type="button"
          >
            Load Previous
          </button>
        </div>
      )}

      {totalProducts > 0 && (
        <ul
          className={`mx-auto grid grid-cols-2 gap-x-5 gap-y-6 ${
            enabledFilters ? 'md:grid-cols-2 lg:grid-cols-4' : 'md:grid-cols-3'
          } md:px-0`}
        >
          {products?.map((product, index) => {
            const promoTile = promoTiles?.find(
              ({ position }) => position === index + 1
            );
            const key = index;
            const page = Math.floor(index / 48) + minLoadedPage; // Adjust the page number calculation

            return (
              <Fragment key={`${currentPage}-${key}`}>
                {promoTile && (
                  <li key={`promo-tile-${key}`}>
                    <CollectionSearchspringPromoTile tile={promoTile} />
                  </li>
                )}
                <li
                  {...((index % 48 === 0 || (index + 1) % 48 === 0) ? { page } : {})}
                  className="flex flex-col"
                  product-id={product.handle}
                  key={`collection-tile-${key}`}
                >
                  <ProductItem
                    enabledColorNameOnHover={
                      productItem?.enabledColorNameOnHover
                    }
                    enabledColorSelector={productItem?.enabledColorSelector}
                    enabledQuickShop={productItem?.enabledQuickShop}
                    enabledStarRating={productItem?.enabledStarRating}
                    enabledProductType={productItem?.enabledProductType}
                    handle={product.handle}
                    index={index}
                    isSearchResults={isSearchResults}
                    isCollection={isCollection}
                    collectionTitle={collectionTitle}
                    collectionHandle={collectionHandle}
                    product={product}
                    swatchesMap={swatchesMap}
                  />
                </li>
              </Fragment>
            );
          })}
        </ul>
      )}

      {hasMoreProducts && pagination && (
        <div
          className="mt-10 flex flex-col items-center gap-3"
          ref={loadMoreRef}
        >
          {progressMessage && (
            <p className="text-label text-center">{progressMessage}</p>
          )}

          <button
            aria-label={pagination.loadText}
            className={`${pagination.buttonStyle}`}
            onClick={loadMoreProducts}
            type="button"
          >
            {pagination.loadText}
          </button>
        </div>
      )}
    </>
  );
}

CollectionSearchspringGrid.displayName = 'CollectionSearchspringGrid';
