import { Link, ThreeTilesRow } from '../../snippets';
import { Schema } from './ThreeTiles.schema';

export function ThreeTiles({ cms }) {
  const { button, heading, slider, hideHeading, section, tiles } = cms;
  const { aspectRatio, bgColor, fullWidth, textColor } = { ...section };

  const { sliderStyle } = { ...slider };
  const isFullBleedAndCentered =
    sliderStyle === 'fullBleed' || sliderStyle === 'fullBleedWithGradient';
  const isLoop = isFullBleedAndCentered || sliderStyle === 'containedWithLoop';
  const maxWidthClass =
    fullWidth || isFullBleedAndCentered
      ? 'max-w-[var(--content-max-xl-width)]'
      : 'max-w-[var(--content-max-lg-width)]';

  return (
    <div
      className="px-contained py-contained"
      style={{ backgroundColor: section?.bgColor }}
    >
      {(heading  || button?.text) && !hideHeading && (
        <div className={`flex items-center justify-between gap-5 mb-5 mx-auto xl:mb-10 ${maxWidthClass}`}>
          {heading && (
            <h2
              className="text-title-h2 text-left"
              style={{ color: textColor }}
            >
              {heading}
            </h2>
          )}
          {button?.text && (
            <div className="mt-10 flex flex-col items-center">
              <Link
                aria-label={
                  button?.newTab 
                    ? `Open in a new tab to view ${button?.text}` 
                    : button?.text
                }
                className={`${section?.buttonStyle || 'btn-primary'}`}
                href={button.url}
                newTab={button.newTab}
                type={button.type}
              >
                {button.text}
              </Link>
            </div>
          )}
        </div>
      )}

      <div className="mx-auto">
        <ThreeTilesRow
          slider={slider}
          sliderStyle={sliderStyle}
          isFullBleedAndCentered={isFullBleedAndCentered}
          isLoop={isLoop}
          heading={heading}
          aspectRatio={aspectRatio}
          maxWidthClass={maxWidthClass}
          textColor={textColor}
          tiles={tiles}
          itemsPerRow={`grid-cols-3`}
        />
      </div>
    </div>
  );
}

ThreeTiles.displayName = 'ThreeTiles';
ThreeTiles.Schema = Schema;
